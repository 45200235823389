/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface MetaTag {
  name: string;
  content: string;
}


type ExtendedBlogPost = {
  frontmatter: {
    publishedYear: string;
    published: string;
  }
} & import("./blog-list").BlogPost;

interface SEOProps {
  description?: string;
  lang?: string;
  title: string;
  blogPost?: ExtendedBlogPost;
  additionalMetaData?: Array<MetaTag>;
  image?: import("gatsby-source-filesystem").FileSystemNode;
  location: Location;
}

const SEO: React.FC<SEOProps> = (props) => {
  let lang = props.lang;
  
  const { site, seo, address, social, menu } = useStaticQuery(
    graphql`
      query {
        seo : generalJson(block: {eq: "seo" }) {
          description
          fullCorporateName
          email
          tagline
          socialImage {
            childImageSharp {
              resize(width: 775, height: 350, cropFocus: CENTER) {
                src
                width
                height
              }
            }
          }
        }
        address: footerJson {
          addressLine1
          addressLine2
          city
          state
          zip
        }
        social: generalJson (block: {eq: "social"}) {
          facebook
          twitter
          instagram
          linkedin
          youtube
        }
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            title
            author
            siteUrl
            copyrightYear
            initialPublication
          }
        }
        menu: allMenuJson {
          edges {
            node {
                label
                link
            }
          }
        }
      }
    `
  )

  const pageUrl = `${site.siteMetadata.siteUrl}${props.location.pathname}`;

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        "@type": "WebPage",
        '@id': site.siteMetadata.siteUrl,
        name: 'Homepage',
      },
      position: 1,
    }].concat(menu.edges.map((e, i) => ({
      '@type': 'ListItem',
      item: {
        "@type": "WebPage",
        '@id': `${site.siteMetadata.siteUrl}${e.node.link}`,
        name: e.node.label,
      },
      position: i + 2,
    })));

  const socialLinks = [
    social.facebook,
    social.twitter,
    social.instagram,
    social.linkedin,
    social.youtube
  ].filter(x => x);

  let title = props.title;
  let image = `${site.siteMetadata.siteUrl}${seo.socialImage.childImageSharp.resize.src}`;
  let imageHeight = seo.socialImage.childImageSharp.resize.height;
  let imageWidth = seo.socialImage.childImageSharp.resize.width;
  
  if(props.image) {
    const resize = (props.image.childImageSharp as any).resize;
    image = `${site.siteMetadata.siteUrl}${resize.src}`;
    imageHeight = resize.height;
    imageWidth = resize.Width;
  }

  let schemaArticle;
  let description = props.description || seo.description;
  if (props.blogPost) {
    const postData = props.blogPost.frontmatter;
    const postImage = (postData.primary_image.childImageSharp as any).fluid;

    title = `${postData.title} - Blog`;
    description = `${props.blogPost.excerpt}...`;
    image = `${site.siteMetadata.siteUrl}${postImage.src}`;
    imageHeight = postImage.presentationHeight;
    imageWidth = postImage.presentationWidth;

    schemaArticle = {
      '@context': 'http://schema.org',
      '@type': 'Article',
      author: {
        '@id': `${site.siteMetadata.siteUrl}/#identity`,
      },
      copyrightHolder: {
        '@id': `${site.siteMetadata.siteUrl}/#identity`,
      },
      copyrightYear: postData.publishedYear,
      creator: {
        '@id': `${site.siteMetadata.siteUrl}/#creator`,
      },
      publisher: {
        '@id': `${site.siteMetadata.siteUrl}/#creator`,
      },
      datePublished: postData.published,
      dateModified: site.buildTime,
      description,
      headline: title,
      inLanguage: lang,
      url: pageUrl,
      name: title,
      image: {
        '@type': 'ImageObject',
        url: image,
      },
      mainEntityOfPage: pageUrl,
    };

    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@type': "WebPage",
        '@id': pageUrl,
        name: title,
      },
      position: itemListElement.length + 1,
    })
  }

  //TODO: Double Check BreadcrumbList VS SiteNavigationElement
  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }

  const orgaCreator = (type: "identity" | "creator") => ({
    '@context': 'http://schema.org',
    '@id': `${site.siteMetadata.siteUrl}/#${type}`,
    '@type': 'Organization',
    address: {
      '@type': 'PostalAddress',
      streetAddress: `${address.addressLine1} ${address.addressLine2}`.trim(),
      addressLocality: address.city,
      addressRegion: address.state,
      postalCode: address.postalCode,
      addressCountry: 'US',
    },
    name: seo.fullCorporateName,
    alternateName: site.siteMetadata.title,
    description: seo.description,
    url: site.siteMetadata.siteUrl,
    email: seo.email,
    founder: seo.fullCorporateName,
    // image: {
    //   '@type': 'ImageObject',
    //   url: config.siteLogo,
    //   height: '512',
    //   width: '512',
    // },
    // logo: {
    //   '@type': 'ImageObject',
    //   url: config.siteLogoSmall,
    //   height: '60',
    //   width: '60',
    // },
    sameAs: socialLinks,
  })

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': props.location.pathname == "/blog" ? 'Blog' : 'WebPage',
    url: pageUrl,
    headline: seo.tagline,
    inLanguage: lang,
    mainEntityOfPage: pageUrl,
    description: description,
    name: site.siteMetadata.title,
    author: {
      '@id': `${site.siteMetadata.siteUrl}/#identity`,
    },
    copyrightHolder: {
      '@id': `${site.siteMetadata.siteUrl}/#identity`,
    },
    copyrightYear: `${site.siteMetadata.copyrightYear}`,
    creator: {
      '@id': `${site.siteMetadata.siteUrl}/#creator`,
    },
    publisher: {
      '@id': `${site.siteMetadata.siteUrl}/#creator`,
    },
    datePublished: site.siteMetadata.initialPublication,
    dateModified: site.buildTime,
    image: {
      '@type': 'ImageObject',
      url: image,
    },
  };



  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:locale`,
          content: lang
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title
        },
        {
          property: `og:type`,
          content: `${props.blogPost ? 'article' : 'website'}`
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: pageUrl
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: image
        },
        {
          property: `og:image:alt`,
          content: description,
        },
        {
          property: `og:image:width`,
          content: imageWidth,
        },
        {
          property: `og:image:height`,
          content: imageHeight
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:image:alt`,
          content: description
        },
        {
          name: `twitter:image:width`,
          content: imageWidth
        },
        {
          name: `twitter:image:height`,
          content: imageHeight
        }
      ].concat(socialLinks.map(s => ({
        property: "og:see_also",
        content: s
      })))
        .concat(props.additionalMetaData)}
    >
      <link type="text/plain" href={`${site.siteMetadata.siteUrl}/humans.txt`} rel="author" />
      {!schemaArticle && <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script> }
      {schemaArticle && <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script> }
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      <script type="application/ld+json">{JSON.stringify(orgaCreator('identity'))}</script>
      <script type="application/ld+json">{JSON.stringify(orgaCreator('creator'))}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  additionalMetaData: [],
  description: ``,
};

export default SEO;
