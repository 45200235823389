import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { Box, Button, Dialog, DialogActions, Grid, IconButton, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React, { FunctionComponent, useEffect, useState } from "react";
import LinkButton from "./link-button";
import { faTimes } from "@fortawesome/free-solid-svg-icons";


interface MessagesDialogProps {

}

const StyledDialog = styled(Dialog)`
    z-index: 2500 !important;
`;

const BookImageContain = styled.div`
    margin: auto;
    max-width: 350px;
`;

const useHiddenCookie = (defaultValue: boolean) => {
    const cookieName = "hide_message";

    const getItem = key => {
        if (typeof window == 'undefined') {
            return false;
        }

        return document.cookie.split("; ").reduce((total, currentCookie) => {
            const item = currentCookie.split("=");
            const storedKey = item[0];
            const storedValue = item[1];
            return key === storedKey
                ? decodeURIComponent(storedValue)
                : total;
        }, '');
    }

    const setItem = (key, value, numberOfMinutes) => {
        const now = new Date();
        // set the time to be now + numberOfDays
        now.setTime(now.getTime() + (numberOfMinutes * 60 * 1000));
        document.cookie = `${key}=${value};     expires=${now.toUTCString()}; path=/`;
    };

    const getCookie = () => (getItem(cookieName) === 'true') || defaultValue;
    const [cookie, setCookie] = useState(getCookie());

    const updateCookie = (numberOfMinutes: number) => {
        setCookie(true);
        setItem(cookieName, true, numberOfMinutes);
    };

    return [cookie, updateCookie] as const;
};

const MessagesDialog: FunctionComponent<MessagesDialogProps> = (props) => {
    const theme = useTheme<Theme>();
    const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
    const [isHidden, setHidden] = useHiddenCookie(false);
    const [isOpen, setOpen] = useState(false);

    const data = useStaticQuery(graphql`
    query {
       messages:generalJson(block: {eq: "messages" }){
        bookImage {
            childImageSharp {
              fluid(maxHeight: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          preOrderImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
       }
    }
`);

    useEffect(() => {
        if (!isHidden) {
            setTimeout(() => setOpen(true), 10_000);
        }
    });

    const handleClose = () => {
        setOpen(false);
        setHidden(10);
    }

    return (
        <StyledDialog
            maxWidth="md"
            open={isOpen && !isHidden}
            fullScreen={isPhone}
        >
            <Box textAlign="right">
                <IconButton onClick={handleClose}><FontAwesomeIcon icon={faTimes} color={theme.palette.primary.contrastText} /></IconButton>
            </Box>
            <Box p={{ xs: 2, sm: 4 }} marginTop={{xs: -4, sm:0}} height="100%" display="flex" alignItems="center">
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Typography align="center" variant="h3">The Doctors Sherzai's Latest Book</Typography>
                        <Typography align="center" variant="h5">Available March 23<sup>rd</sup> 2021</Typography>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Box hidden={isPhone} px={{ xs: 4, sm: 0 }} ml={{ sm: 4 }}>
                            <Img fluid={data.messages.preOrderImage.childImageSharp.fluid} alt="$700 worth of Bonus Material" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Box px={{xs: 4, sm: 0}}>
                            <BookImageContain>
                                <Img fluid={data.messages.bookImage.childImageSharp.fluid} />
                            </BookImageContain>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box px={{ xs:2, sm: 8 }}>
                            <Typography variant="h5">
                                Pre-order The 30-Day Alzheimer’s Solution to access $700 worth of bonus materials, including the 30-Day NEURO Web Course.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box textAlign="center" pt={3}>
                            <LinkButton url="https://teamsherzai.com/30-day-alzheimers-solution/#pre-order-details?utm_source=the-neuro-plan&utm_medium=takeover" variant="contained" color="secondary" size="medium">
                                <Typography variant="h5">Pre-Order Today</Typography>
                            </LinkButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </StyledDialog>
    )
};

export default MessagesDialog;