import React, { FunctionComponent } from 'react';
import NavBar from '../components/navBar';
import Footer from '../components/footer';
import styled from '@emotion/styled';
import { useTheme, Theme } from "@material-ui/core";
import "typeface-asap-condensed";
import "typeface-open-sans";
import "./icons";
import MessagesDialog from '../components/messages-dialog';

interface LayoutProps {
    transparentNav?: boolean;
}

const HundyP = styled.main((props: { theme: Theme }) => `
  min-height: calc(100vh - 323px);

  ${props.theme.breakpoints.down("xs")} {
      min-height: calc(100vh - 648px);
  }
`);

const ToolbarCorrect = styled.div`
    min-height: 64px;
`;

const MainLayout: FunctionComponent<LayoutProps> = (props) => {
    const theme = useTheme<Theme>();

    return (
        <>
            <NavBar allowTransparent={props.transparentNav} color={theme.palette.primary.contrastText} backgroundColor={theme.palette.primary.main} />
            <HundyP theme={theme}>
                <ToolbarCorrect />
                {props.children}
            </HundyP>
            <Footer />
            <MessagesDialog />
        </>
    )
};

export default MainLayout;