import React, { FunctionComponent, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Hidden, Drawer, List, ListItem, ListItemText, IconButton, useScrollTrigger, Box, Theme, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faTwitterSquare, faInstagramSquare, faYoutubeSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from "gatsby-image";
import styled from '@emotion/styled';

interface NavBarProps {
    allowTransparent: boolean;
    backgroundColor: string;
    color: string;
}

const LogoDiv = styled.div`
    flex-grow:1;
    padding: 4px 0px;
`;

const ToolbarHeightCorrect = styled.div`
    padding-top: 74px;
`;

const SocialIcon = styled(FontAwesomeIcon)`
    width: 0.875em;
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
`;

const NavTextLink = styled<typeof Link, { color: string }>(Link, {
    shouldForwardProp: prop => prop !== "color"
})(props => `
    color: ${props.color};
    text-decoration: none;
`);

const NavAppBar = styled(AppBar, {
    shouldForwardProp: prop => prop !== "transparent" && prop !== "background"
})((props: { transparent: boolean, background: string }) => `
    background-color:${props.transparent ? 'transparent' : props.background};
    transition: background-color 0.5s ease-in-out;
`);

const NotCaps = styled(Typography)`
    text-transform:none;
`;

const NotCapsLIT = styled(ListItemText)`
    text-transform:none;
`;

const AppDrawerStyled = styled.div((props: { color: string, backgroundColor: string}) => `
    background-color:${props.backgroundColor};
    color:${props.color};
`);


const NavAppBarStyled = styled(NavAppBar,
    { shouldForwardProp: prop => prop !== "theme" && prop !== "drawerOpen" })((props: { theme: Theme, drawerOpen: boolean }) => `
    z-index: ${props.drawerOpen ? props.theme.zIndex.drawer + 200 : 1000}
`);

const NavBar: FunctionComponent<NavBarProps> = (props) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();

    const transparent = !useScrollTrigger({
        disableHysteresis: true,
        threshold: 1
    }) && props.allowTransparent && !drawerOpen;

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                title
                }
            }
            allMenuJson {
                edges {
                node {
                    label
                    link
                }
                }
            }
            seo: generalJson(block: {eq: "seo" }) {
                colorLogo {
                    childImageSharp{
                        fluid (maxWidth:450){
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
                altLogo {
                    childImageSharp{
                        fluid (maxWidth:450){
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
            social: generalJson (block: { eq: "social" }) {
                facebook
                twitter
                instagram
                youtube
                linkedin
            }
        }
  `);

    const toggleDrawer = (open: boolean) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerOpen(open);
    };

    const linkProps = (url: string) => {
        let props = {};

        if (url.match(/^https?:\/\//)) {
            props = {
                target: "__blank",
                rel: "nofollow noopener"
            };
        }

        return props;
    }

    const getImageProps = () => {
        let props = {}

        const primaryLogo = data.seo.colorLogo.childImageSharp;
        const altLogo = data.seo.altLogo.childImageSharp;

        if (primaryLogo.fluid) {
            props = {
                fluid: transparent ? primaryLogo.fluid : altLogo.fluid
            }
        }

        if (primaryLogo.fixed) {
            props = {
                fixed: transparent ? primaryLogo.fixed : altLogo.fixed
            }
        }

        return props;
    }

    return (
        <>
            <NavAppBarStyled elevation={transparent ? 0 : 4} transparent={transparent} background={props.backgroundColor}
                position="fixed" theme={theme} drawerOpen={drawerOpen}>
                <Toolbar>
                    <LogoDiv>
                        <NavTextLink color={props.color} to="/">
                            <Box pr={4} maxWidth={350}>
                                <Img loading="eager" fadeIn={false} {...getImageProps()} />
                            </Box>
                        </NavTextLink>
                    </LogoDiv>
                    <Hidden smDown implementation="css">
                        {data.allMenuJson.edges.map((x: { node: { link: string; label: React.ReactNode; }; }) =>
                            <Button color={props.color} key={x.node.link} to={x.node.link} {...linkProps(x.node.link)}
                                component={Link} size="medium">
                                <NotCaps variant="h6" component="span">{x.node.label}</NotCaps>
                            </Button>
                        )}
                    </Hidden>
                    {props.shoppingCart && <ShoppingCartButton color={props.color} />}
                    <Hidden smDown implementation="css">
                        {data.social.facebook &&
                            <a href={data.social.facebook} target="_blank" rel="noopener">
                                <IconButton><SocialIcon icon={faFacebookSquare} color={props.color} /></IconButton>
                            </a>
                        }
                        {data.social.twitter &&
                            <a href={data.social.twitter} target="_blank" rel="noopener">
                                <IconButton><SocialIcon icon={faTwitterSquare} color={props.color} /></IconButton>
                            </a>
                        }
                        {data.social.instagram &&
                            <a href={data.social.instagram} target="_blank" rel="noopener">
                                <IconButton><SocialIcon icon={faInstagramSquare} color={props.color} /></IconButton>
                            </a>
                        }
                        {data.social.linkedin &&
                            <a href={data.social.linkedin} target="_blank" rel="noopener">
                                <IconButton><FontAwesomeIcon icon={faLinkedin} color={props.color} /></IconButton>
                            </a>
                        }
                        {data.social.youtube &&
                            <a href={data.social.youtube} target="_blank" rel="noopener">
                                <IconButton><FontAwesomeIcon icon={faYoutubeSquare} color={props.color} /></IconButton>
                            </a>
                        }
                    </Hidden>
                    <Hidden mdUp implementation="css">
                        <IconButton
                            color={props.color}
                            aria-label="open drawer"
                            onClick={toggleDrawer(!drawerOpen)}
                            edge="start"
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </IconButton>
                    </Hidden>
                </Toolbar>
            </NavAppBarStyled>
            <Drawer anchor="top" open={drawerOpen} onClose={toggleDrawer(false)}>
                <AppDrawerStyled backgroundColor={props.backgroundColor} color={props.color}>
                    <ToolbarHeightCorrect />
                    <List>
                        {data.allMenuJson.edges.map((x: { node: { link: string; label: React.ReactNode; }; }) =>
                            <ListItem button key={x.node.link} to={x.node.link} {...linkProps(x.node.link)} component={Link} onClick={toggleDrawer(false)}>
                                <NotCapsLIT color="white">{x.node.label}</NotCapsLIT>
                            </ListItem>
                        )}
                        <ListItem>
                            {data.social.facebook &&
                                <a href={data.social.facebook} target="_blank" rel="noopener">
                                    <IconButton><FontAwesomeIcon icon={faFacebookSquare} color={props.color} /></IconButton>
                                </a>
                            }
                            {data.social.twitter &&
                                <a href={data.social.twitter} target="_blank" rel="noopener">
                                    <IconButton><FontAwesomeIcon icon={faTwitterSquare} color={props.color} /></IconButton>
                                </a>
                            }
                            {data.social.instagram &&
                                <a href={data.social.instagram} target="_blank" rel="noopener">
                                    <IconButton><FontAwesomeIcon icon={faInstagramSquare} color={props.color} /></IconButton>
                                </a>
                            }
                            {data.social.linkedin &&
                                <a href={data.social.linkedin} target="_blank" rel="noopener">
                                    <IconButton><FontAwesomeIcon icon={faLinkedin} color={props.color} /></IconButton>
                                </a>
                            }
                            {data.social.youtube &&
                                <a href={data.social.youtube} target="_blank" rel="noopener">
                                    <IconButton><FontAwesomeIcon icon={faYoutubeSquare} color={props.color} /></IconButton>
                                </a>
                            }
                        </ListItem>
                    </List>
                </AppDrawerStyled>
            </Drawer>
        </>)
};

export default NavBar;