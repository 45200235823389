import { Button } from '@material-ui/core';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'gatsby';

type LinkButtonProps =  { url: string; } & import('@material-ui/core/Button').ButtonProps;


const LinkButton : React.FC<LinkButtonProps> = (props) => {
    const {url, ...otherProps} = props;

    if(url.match(/^https?:\/\//)) {
        return <Button {...otherProps} href={url} target="__blank" rel="nofollow noopener">{props.children}</Button>
    } else if(url.startsWith("#")){
        return <Button {...otherProps} href={url} component={AnchorLink}>{props.children}</Button>
    }

    return <Button {...otherProps} component={Link} to={url}>{props.children}</Button>
}

export default LinkButton;