import React, { FunctionComponent } from "react";
import { useTheme, Theme, Box, Grid, Typography, Container, Button, IconButton } from "@material-ui/core";
import Section from "./section";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faTwitterSquare, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
import styled from "@emotion/styled";

type bp = import('@material-ui/core/Box').BoxProps;
const CenterWhenSmall = styled<React.ComponentType<bp>, { theme: Theme }>(Box)(props =>
(`
    justify-content: flex-start;

    ${props.theme.breakpoints.down("xs")} {
        justify-content: center;
        align-items: center;
    }
`));

const ByLink = styled.a<{theme: Theme}>(props => (`
    text-decoration: none;
    color: ${props.theme.palette.primary.contrastText};
`));

const NotCaps = styled(Typography)`
    text-transform:none;
`;

const NoMinWidth = styled(Button)`
    min-width:32px;
`;

const Footer: FunctionComponent = () => {
    const theme = useTheme<Theme>();

    const data = useStaticQuery(graphql`
        query {
            footerJson {
                tagline
                addressLine1
                addressLine2
                city
                state
                zip
                logo {
                    childImageSharp {
                        fixed( width:320, height:46, cropFocus: CENTER) {
                          ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            allMenuJson {
                edges {
                node {
                    label
                    link
                }
                }
            }
            social: generalJson (block: {eq: "social"}) {
                facebook
                twitter
                instagram
                linkedin
                youtube
            }
        }
    `);

    return (
        <Section noBottom tightTop bgcolor={theme.palette.primary.dark} color={theme.palette.primary.contrastText}>
            <Container>
                <Box pb={2}>
                    <Grid container>
                        <Grid item xs={12} sm={5}>
                            <CenterWhenSmall theme={theme} display="flex" alignItems="center" pt={1}>
                                <div>
                                    <Typography variant="h5" align="center">{data.footerJson.tagline}</Typography>
                                    <Box py={4}>
                                    <a href="http://teamsherzai.com/" target="__blank" rel="noopener">
                                         <Img fixed={data.footerJson.logo.childImageSharp.fixed} />
                                    </a>
                                    </Box>
                                </div>
                            </CenterWhenSmall>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CenterWhenSmall theme={theme} display="flex" flexDirection="column" alignItems="flex-start">
                                {data.allMenuJson.edges.map((x: { node: { link: string; label: React.ReactNode; }; }) =>
                                    <NoMinWidth color="inherit" key={x.node.link} to={x.node.link}
                                        component={Link}>
                                        <NotCaps variant="h6" component="span">{x.node.label}</NotCaps>
                                    </NoMinWidth>
                                )}
                            </CenterWhenSmall>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <CenterWhenSmall theme={theme} display="flex" flexDirection="column" pt={1}>
                                <Typography variant="h6" align="right">{data.footerJson.addressLine1}</Typography>
                                <Typography variant="h6" align="right">{data.footerJson.addressLine2}</Typography>
                                <Typography variant="h6" align="right">{data.footerJson.city}, {data.footerJson.state} {data.footerJson.zip}</Typography>
                                <Box display="flex" justifyContent="flex-end" mr={-2} pt={2}>
                                    {data.social.facebook &&
                                        <a href={data.social.facebook} target="_blank" rel="noopener">
                                            <IconButton><FontAwesomeIcon icon={faFacebookSquare} color={theme.palette.primary.contrastText} /></IconButton>
                                        </a>
                                    }
                                    {data.social.twitter &&
                                        <a href={data.social.twitter} target="_blank" rel="noopener">
                                            <IconButton><FontAwesomeIcon icon={faTwitterSquare} color={theme.palette.primary.contrastText} /></IconButton>
                                        </a>
                                    }
                                    {data.social.instagram &&
                                        <a href={data.social.instagram} target="_blank" rel="noopener">
                                            <IconButton><FontAwesomeIcon icon={faInstagram} color={theme.palette.primary.contrastText} /></IconButton>
                                        </a>
                                    }
                                    {data.social.linkedin &&
                                        <a href={data.social.linkedin} target="_blank" rel="noopener">
                                            <IconButton><FontAwesomeIcon icon={faLinkedin} color={theme.palette.primary.contrastText} /></IconButton>
                                        </a>
                                    }
                                    {data.social.youtube &&
                                        <a href={data.social.youtube} target="_blank" rel="noopener">
                                            <IconButton><FontAwesomeIcon icon={faYoutube} color={theme.palette.primary.contrastText} /></IconButton>
                                        </a>
                                    }
                                </Box>
                            </CenterWhenSmall>
                        </Grid>
                    </Grid>
                </Box>
                <Box py={1}>
                    <Typography align="center" variant="caption" component="div">© 2019 Brain Initiative LLC. All rights reserved.</Typography>
                    <Typography align="center" variant="caption" component="div"><ByLink theme={theme} href="https://www.atldmg.com" target="_blank" rel="noopener">Website By Atlanta Digital Marketing Group</ByLink></Typography>
                </Box>
            </Container>
        </Section>
    )
}

export default Footer;